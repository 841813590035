


































































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import GeneralHelper from '@/shared/helpers/general';
import paymentsService from '@/services/sparkecommerce/payments';

@Component
export default class UnitarySalesRefundSidebar extends Vue {
  @Prop({ type: Object, required: true }) sale!: any;

  public showCpf: boolean = false;

  isLoadingRequest = false;

  async sendRefundRequest() {
    const refundPaymentId = this.sale.saleId;
    this.isLoadingRequest = true;

    try {
      await paymentsService.refund(refundPaymentId);
      this.$emit('refunded');
    } catch {
      this.$emit('error');
    } finally {
      this.isLoadingRequest = false;
    }
  }

  formatCPF(cpf) {
    return cpf ? GeneralHelper.cpf(cpf, !this.showCpf) : '-';
  }

  get descriptionText() {
    return this.$t('sparkpay.sales.refund-sidebar.description');
  }

  get limitDate() {
    return this.sale.refundLimitDate ? dayjs(this.sale.refundLimitDate).format('DD/MM/YYYY') : '—';
  }

  get warningText() {
    return this.$t('sparkpay.sales.refund-sidebar.warning');
  }
}
